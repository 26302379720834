// Libs
import React from 'react';
import moment from 'moment';

// Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import ButtonOptions from 'components/common/ButtonOptions';

//-------------------------------------------------------------------------------------------------------------------

class PreBookAppt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedAppt: '',
            lookAheadMonths: 0,
        };
    }

    setLookAheadMonths(lookAheadMonths) {
        const dateFrom = moment(this.props.appt.date).add(lookAheadMonths, 'months').startOf('isoWeek').format('YYYY-MM-DD')
        this.setState({
            lookAheadMonths,
            dateFrom,
            isLoading: true
        }, () => {
            this.load()
        });
    }

    async load() {
        const { appt } = this.props;
        const { dateFrom } = this.state;
        this.setState({ isLoading: true });
        const { suggestedAppts } = await API.call('auto-planner/list-suggested-appts', {
            dateFrom,
            accountID: appt.accountID,
            newAppt: {
                duration: appt.duration
            }
        });
        let suggestedApptsByDate = {};
        suggestedAppts.forEach(a => {
            if (!suggestedApptsByDate[a.date]) {
                suggestedApptsByDate[a.date] = {
                    date: a.date,
                    appts: []
                };
            }
            suggestedApptsByDate[a.date].appts.push(a);
        });
        suggestedApptsByDate = Object.values(suggestedApptsByDate);

        this.setState({
            isLoading: false,
            suggestedAppts,
            suggestedApptsByDate
        });
    }

    changeDate(delta) {
        const { dateFrom } = this.state;
        this.setState({
            isLoading: true,
            suggestedAppts: null,
            dateFrom: moment(dateFrom).add(delta, 'days').format('YYYY-MM-DD')
        }, () => {
            this.load();
        });
    }

    confirmBook() {
        if (window.confirm('Book this appointment now?')) {
            this.book();
        }
    }

    async book() {
        const { appt, selectedAppt } = this.props;
        this.setState({ isLoading: true });
        try {
            const selectedApptParts = selectedAppt.split(',');
            const date = selectedApptParts[0];
            const time = selectedApptParts[1];
            const operativeUserID = selectedApptParts[2];
            const { id } = await API.call('appt/prebook', {
                templateApptID: appt.id,
                date,
                time,
                operativeUserID
            });
            this.props.updateFields({ preBookApptID: id }, true);
        } catch (e) {
            alert('Problem booking next appointment: ' + e);
        }
        this.setState({ isLoading: false });
    }

    render() {
        const { selectedAppt } = this.props;
        const { isLoading, lookAheadMonths, suggestedApptsByDate, dateFrom } = this.state;

        return (<>
            <ButtonOptions
                options={[
                    { value: 6, text: '+6 months' },
                    { value: 12, text: '+12 months' },
                    { value: 18, text: '+18 months' },
                    { value: 24, text: '+24 months' }
                ]}
                value={lookAheadMonths}
                onSelect={lookAheadMonths => this.setLookAheadMonths(lookAheadMonths)}
                className="mb-3"
            />
            {isLoading &&
                <Loader />
            }
            {dateFrom && !isLoading && <>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <button className="btn btn-secondary me-3" onClick={() => this.changeDate(-7)}>
                        -1 Week
                    </button>
                    {moment(dateFrom).format('DD/MM/YYYY')}
                    <button className="btn btn-secondary ms-3" onClick={() => this.changeDate(+7)}>
                        +1 Week
                    </button>
                </div>

                <hr />

                {suggestedApptsByDate.map(sad =>
                    <div key={sad.date} className="suggested-appt-date">
                        <div className="suggested-appt-date-title">
                            {moment(sad.date).format('dddd, DD MMM YYYY')}
                        </div>
                        <div className="suggested-appts">
                            <ButtonOptions
                                options={sad.appts.map(a => ({
                                    value: moment(a.date).format('YYYY-MM-DD') + ',' + a.time + ',' + a.operative.id,
                                    text: `${a.time} with ${a.operative.nickname}`
                                }))}
                                value={selectedAppt}
                                onSelect={preBookAppt => this.props.updateFields({ preBookAppt }, true)}
                            />
                        </div>
                    </div>
                )}

                {selectedAppt &&
                    <div className="mt-3">
                        <hr />
                        <button className="btn btn-primary" onClick={() => this.confirmBook()}>
                            <span className="fa fa-check" />{' '}
                            Book next appointment now
                        </button>
                    </div>
                }

            </>}
        </>);
    }

}

export default PreBookAppt;