// Libs
import React from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

// Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import ButtonOptions from 'components/common/ButtonOptions';
import EditCookerModal from 'components/EditCookerModal';

//-------------------------------------------------------------------------------------------------------------------

class ApptJobs extends React.Component {

    constructor(props) {
        super(props);
        this.editCookerModalRef = React.createRef();
        this.state = {
            isEditMode: false
        };
    }

    confirmRemoveService(cookerID, serviceTypeID) {
        const confirm = window.confirm('Are you sure you want to remove this job from the appointment?');
        if (confirm) {
            this.props.removeService(cookerID, serviceTypeID);
        }
    }

    async editCooker(cooker) {
        const { appt } = this.props;
        const isChanged = await this.editCookerModalRef.current.open({
            cooker,
            propertyID: appt.propertyID,
            accountID: appt.accountID
        });
        if (isChanged) {
            this.props.load();
        }
    }

    async addCooker() {
        const { appt } = this.props;
        const cooker = {
            id: TextHelpers.getRandomGUID(),
            floor: 'Ground',
            location: 'Living Room',
            propertyID: appt.propertyID
        };
        await this.editCooker(cooker);
        this.props.load();
    }
    
    render() {
        const { appt } = this.props;
        const { isEditMode } = this.state;

        return (<>
            <div className="appt-jobs">

                <label className="checkbox-label add-jobs-checkbox">
                    <input
                        type="checkbox"
                        checked={isEditMode || false}
                        onChange={e => this.setState({ isEditMode: e.target.checked })}
                    />{' '}
                    Edit mode
                </label>

                {appt.property.cookers.map((c, index) =>
                    this.renderCooker(c, index)
                )}

                {this.renderOtherServices()}

                {isEditMode &&
                    <button type="button" className="btn btn-secondary" onClick={() => this.addCooker()}>
                        Add a new cooker
                    </button>
                }

            </div>

            <EditCookerModal ref={this.editCookerModalRef} />
        </>);
    }

    renderCooker(cooker, index) {
        const { appt } = this.props;
        const { isEditMode } = this.state;

        const hasAnyServices = appt.services.find(ast => ast.cookerID == cooker.id);
        if (!hasAnyServices && !isEditMode) {
            return null;
        }
        const cookerType = (cooker.cookerTypeID ? API.cookerTypesByID[cooker.cookerTypeID] : null);
        const cleanService = appt.services.find(ast => ast.cookerID == cooker.id && API.serviceTypesByID[ast.serviceTypeID].code == 'clean');
        
        return (
            <div key={cooker.id} className="cooker">

                <h2>
                    <span>
                        {cookerType && <>{' '}{cookerType.name},{' '}</>}
                        {cooker.floor} floor {cooker.location}

                        {isEditMode &&
                            <button
                                type="button"
                                className="btn btn-secondary edit-cooker-btn"
                                onClick={() => this.editCooker(cooker)}
                            >
                                <span className="fa-solid fa-pencil" />
                            </button>
                        }

                    </span>
                </h2>

                <div className="cooker-services">

                    {(cleanService || isEditMode) &&
                        <div className={`cooker-service ${cleanService ? 'active' : ''}`}>
                            <div className="service-type">
                                <span>Clean</span>
                            </div>
                            <div className="service-results">

                                {cleanService ?
                                    this.renderCleanResults(cleanService) :
                                    <div className="empty-text">
                                        <button className="btn btn-secondary add-service-btn" onClick={() => this.props.addService(cooker.id, 'clean')}>
                                            <span className="fa fa-plus" />{' '}
                                            Add
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                </div>

            </div>
        );
    }

    renderCleanResults(service) {
        const { isEditMode } = this.state;

        return (<>

            {isEditMode &&
                <button type="button" className="btn btn-danger delete-service-btn" onClick={() => this.confirmRemoveService(service.cookerID, service.serviceTypeID)}>
                    <span className="fa-solid fa-remove" />
                </button>
            }

            {service.cookerID &&
                <div className="form-group">
                    <label className="form-label">Cooker Notes</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.cooker.notes || ''}
                        defaultValue={service.cooker.notes || ''}
                        onBlur={e => this.props.updateCookerFields(service.cookerID, { notes: e.target.value })}
                        rows={2}
                    />
                </div>
            }

            {this.renderStatusFields(service, true)}

            {/* {this.renderNotesField(service)}*/}

            <div className="form-group">
                <label className="form-label">Recommendations</label>
                <ExpandingTextArea
                    className="form-control"
                    key={service.otherRecommendations || ''}
                    defaultValue={service.otherRecommendations || ''}
                    onBlur={e => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { otherRecommendations: e.target.value }, true)}
                    rows={2}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Carbon monoxide alarm</label>
                <ButtonOptions 
                    options={[
                        { value: 'Working', text: 'Fitted & working' },
                        { value: 'NotTested', text: 'Fitted, not tested' },
                        { value: 'NotWorking', text: 'Fitted, not working' },
                        { value: 'NotFitted', text: 'Not fitted' }
                    ]}
                    value={service.coAlarmStatus}
                    onSelect={value => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { coAlarmStatus: value }, true)}
                />
            </div>
            {service.coAlarmStatus != 'NotFitted' &&
                <div className="form-group">
                    <label className="form-label">Carbon monoxide alarm in correct location</label>
                    <ButtonOptions
                        options={[
                            { value: true, text: 'Yes' },
                            { value: false, text: 'No' }
                        ]}
                        value={service.isCOAlarmLocationCorrect}
                        onSelect={value => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { isCOAlarmLocationCorrect: value }, true)}
                    />
                </div>
            }
            <div className="form-group">
                <label className="form-label">Next clean recommended in...</label>
                <ButtonOptions
                    options={[
                        { value: null, text: 'N/A' },
                        { value: 1, text: '1 Month' },
                        { value: 3, text: '3 Months' },
                        { value: 6, text: '6 Months' },
                        { value: 12, text: '12 Months' },
                        { value: 24, text: '24 Months' }//,
                        //{ value: 36, text: '36 Months' },
                        //{ value: 48, text: '48 Months' }
                    ]}
                    value={service.nextCleanInMonths}
                    onSelect={value => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { nextCleanInMonths: value }, true)}
                />
            </div>
        </>);
    }

    renderStatusFields(service, allowCondemn) {
        const statusOptions = [
            { value: 'OK', text: 'Completed ok' },
            { value: 'Fail', text: 'Could not complete' },
        ];
        if (allowCondemn) {
            statusOptions.push({ value: 'Condemn', text: 'Not safe for use (condemn)' });
        }

        return (<>

            <div className="form-group">
                <label className="form-label">Result</label>
                <ButtonOptions
                    options={statusOptions}
                    value={service.status}
                    onSelect={value => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { status: value }, true)}
                />
            </div>

            {service.status == 'Fail' && <>
                <div className="form-group">
                    <label className="form-label">Main reason for failure</label>
                    <ButtonOptions
                        options={[
                            { value: 'NoAccess', text: 'No access' },
                            { value: 'Other', text: 'Other (please specify)' }
                        ]}
                        value={service.failReason}
                        onSelect={value => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { failReason: value }, true)}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Failure Notes</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.failReasonNotes || ''}
                        defaultValue={service.failReasonNotes || ''}
                        onBlur={e => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { failReasonNotes: e.target.value }, true)}
                        rows={2}
                    />
                </div>
            </>}

            {allowCondemn && service.status == 'Condemn' &&
                <div className="form-group">
                    <label className="form-label">Details of condemnation</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.condemnNotes || ''}
                        defaultValue={service.condemnNotes || ''}
                        onBlur={e => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { condemnNotes: e.target.value }, true)}
                        rows={2}
                    />
                </div>
            }

        </>);
    }

    renderNotesField(service) {
        return (
            <div className="form-group">
                <label className="form-label">Notes (internal)</label>
                <ExpandingTextArea
                    className="form-control"
                    key={service.notes || ''}
                    defaultValue={service.notes || ''}
                    onBlur={e => this.props.updateServiceFields(service.cookerID, service.serviceTypeID, { notes: e.target.value }, true)}
                    rows={2}
                />
            </div>
        );
    }

    renderOtherServices() {
        const { appt } = this.props;
        const { isEditMode } = this.state;
        const serviceTypes = API.listServiceTypes().filter(st => !st.requiresCooker);
        const apptServices = [];
        const apptServicesLookup = {};
        appt.services.forEach(ast => {
            if (!ast.serviceType.requiresCooker) {
                apptServicesLookup[ast.serviceTypeID] = true;
                apptServices.push(ast);
            }
        });
        if (!isEditMode && apptServices.length == 0) {
            return null;
        }

        return (<>

            {isEditMode && <>
                <h2>Other jobs</h2>
                <ButtonOptions
                    className="vertical-stack mb-3"
                    options={serviceTypes.map(st => ({
                        value: st.id,
                        text: st.name
                    }))}
                    value={apptServicesLookup}
                    //value={service.coAlarmStatus}
                    onSelect={serviceTypeID => {
                        if (apptServicesLookup[serviceTypeID]) {
                            this.props.removeNonCookerService(serviceTypeID);
                        } else {
                            this.props.addNonCookerService(serviceTypeID);
                        }
                    }}
                />
            </>}

            {apptServices.map(ast =>
                <React.Fragment key={ast.serviceTypeID}>
                    <h2>{ast.serviceType.name}</h2>

                    {this.renderStatusFields(ast, false)}

                    {this.renderNotesField(ast)}
                    
                </React.Fragment>
            )}

        </>);
    }
}

export default ApptJobs;