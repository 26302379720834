// Libs
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Components
import App from 'App';

// JS 
import "bootstrap/dist/js/bootstrap.min.js";

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './scss/main.scss'

//-------------------------------------------------------------------------------------------------------------------

const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
  rootElement);

//registerServiceWorker();

