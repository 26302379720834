import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import TopNav       from 'components/layout/TopNav';

//-------------------------------------------------------------------------------------------------------------------

class Layout extends Component {

    render() {
        return (<>
            
            <TopNav {...this.props} />

            <main>

                {this.props.children}

            </main>

        </>);
    }
}

export default withRouter(Layout);