// Libs
import React from 'react';

// Helpers
import TextHelpers from 'helpers/TextHelpers';

// Components
import CurrencyInput from 'components/common/CurrencyInput';

//-------------------------------------------------------------------------------------------------------------------

class ApptProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    confirmRemove(id) {
        const confirm = window.confirm('Are you sure you want to remove this product?');
        if (confirm) {
            this.props.removeProduct(id);
        }
    }

    render() {
        const { appt } = this.props;
        let { products } = this.props; 

        const apptProducts = [...this.props.appt.products];
        if (apptProducts.findIndex(p => p.isNew) == -1) {
            apptProducts.push({
                id: TextHelpers.getRandomGUID(),
                isNew: true
            });
        }

        return (
            <div className="appt-products">

                <table className="table table-bordered appt-products-table">
                    <thead>
                        <tr>
                            <th className="name-col">Product</th>
                            <th className="unit-price-col">Unit price</th>
                            <th className="quantity-col">Quantity</th>
                            <th className="total-price-col">Total price</th>
                            <th className="actions-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {apptProducts.map(ap =>
                            <tr key={ap.id || 'new'}>
                                <td className="name-col">

                                    <select
                                        className="form-control"
                                        value={ap.isNew ? 'NEW' : (ap.productID || '')}
                                        onChange={e => this.props.updateProductFields(ap.id, { productID: e.target.value }, true)}
                                    >
                                        {ap.isNew &&
                                            <option value="NEW">(Please select)</option>
                                        }
                                        {products.map(p =>
                                            <option key={p.id} value={p.id}>
                                                {p.name}
                                            </option> 
                                        )}
                                        <option value="">Other</option>
                                    </select>

                                    {!ap.isNew && !ap.productID &&
                                        <input
                                            type="text"
                                            className="form-control"
                                            key={ap.name || ''}
                                            defaultValue={ap.name || ''}
                                            onBlur={e => this.props.updateProductFields(ap.id, { name: e.target.value }, true)}
                                            placeholder="Enter name of product or service"
                                        />
                                    }
                                
                                </td>
                                <td className="unit-price-col">

                                    {!ap.isNew &&
                                        <CurrencyInput
                                            className="form-control"
                                            value={ap.unitPrice || ''}
                                            onValueChange={(values) => {
                                                const unitPrice = Number(values.value) || '';
                                                this.props.updateProductFields(ap.id, { unitPrice }, false);
                                            }}
                                            onBlur={(unitPrice, e) => this.props.updateProductFields(ap.id, { unitPrice }, true)}
                                        />
                                    }
                                
                                </td>
                                <td className="quantity-col">

                                    {!ap.isNew &&
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={ap.quantity || ''}
                                            onChange={e => this.props.updateProductFields(ap.id, { quantity: e.target.value }, false)}
                                            onBlur={e => this.props.updateProductFields(ap.id, { quantity: e.target.value }, true)}
                                        />
                                    }
                                
                                </td>
                                <td className="total-price-col">

                                    {!ap.isNew && TextHelpers.formatCurrency(ap.totalPrice)}

                                </td>
                                <td className="actions-col">

                                    {!ap.isNew &&
                                        <button type="button" className="btn btn-danger" onClick={() => this.confirmRemove(ap.id)}>
                                            <span className="fa-solid fa-times" />
                                        </button>
                                    }

                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                TOTAL
                            </td>
                            <td className="total-price-col">
                                {TextHelpers.formatCurrency(appt.productPrice)}
                            </td>
                            <td className="actions-col"></td>
                        </tr>
                    </tfoot>
                </table>

            </div>
        );
    }

}

export default ApptProducts;