import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ExpandingTextArea from 'react-expanding-textarea';
import API from 'API';

// Helpers
import ReferenceHelpers from 'helpers/ReferenceHelpers';

import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditCookerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                isChanged: false,
                accountID: opt.accountID,
                propertyID: opt.propertyID,
                cooker: { ...opt.cooker },
                newCookerTypeName: null
            }, () => {
                this.load();
            });
        });
    }

    async saveAndClose() {
        const { cooker, newCookerTypeName } = this.state;
        if (cooker.cookerTypeID == 'new') {
            if (newCookerTypeName) {
                const cookerTypeID = await API.addCookerType(newCookerTypeName, this.state.accountID);
                await this.updateFields({ cookerTypeID });
            } else {
                alert('Please enter a cooker type name')
            }
        }
        this.resolve(this.state.isChanged);
        this.setState({ isOpen: false });
    }

    async load() {
        const cookerTypes = await API.listCookerTypes(this.state.accountID);
        this.setState({
            cookerTypes,
            isLoading: false
        });
    }

    async updateFields(fields) {
        return new Promise(async (resolve, reject) => {
            const { propertyID } = this.state;
            const cooker = { ...this.state.cooker };
            for (let fieldName in fields) {
                const value = fields[fieldName];
                cooker[fieldName] = value;
                await API.saveCooker(propertyID, cooker.id, { [fieldName]: value });
            }
            this.setState({
                cooker,
                isChanged: true
            }, resolve);
        });
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        if (isLoading) {
            return (
                <Modal show onHide={() => this.close()} className="edit-cooker-modal" backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <Loader />
                    </Modal.Body>
                </Modal>
            );
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-cooker-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary ms-auto">
                            Done
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { cooker, cookerTypes, newCookerTypeName } = this.state;

        // Set up select options
        let cookerTypeOptions = [
            { label: '(Unspecified)', value: '' }
        ];
        cookerTypeOptions = cookerTypeOptions.concat(cookerTypes.map(ct => ({
            value: ct.id,
            label: (ct.code ? ct.code + ' - ' : '') + ct.name
        })));
        //let floorOptions = [
        //    { label: '(Unspecified)', value: '' }
        //];
        //floorOptions = floorOptions.concat(ReferenceHelpers.Floors);
        //if (!floorOptions.find(f => cooker.floor == f.value)) {
        //    floorOptions.push({ value: cooker.floor, label: cooker.floor });
        //}

        //let locationOptions = [
        //    { label: '(Unspecified)', value: '' }
        //];
        //locationOptions = locationOptions.concat(ReferenceHelpers.Locations);
        //if (!locationOptions.find(f => cooker.location == f.value)) {
        //    locationOptions.push({ value: cooker.location, label: cooker.location });
        //}
        
        return (<>

            <div className="form-group">
                <label className="form-label">Type</label>
                <select
                    className="form-control"
                    value={cooker.cookerTypeID || ''}
                    onChange={e => this.updateFields({ cookerTypeID: e.target.value })}
                >
                    {cookerTypeOptions.map(ct =>
                        <option key={ct.value} value={ct.value}>
                            {ct.label}
                        </option>
                    )}
                    <option value="new">(Add new...)</option>
                </select>
                {cooker.cookerTypeID == 'new' &&
                    <input
                        type="text"
                        autoFocus
                        className="form-control"
                        placeholder="Enter name..."
                        value={newCookerTypeName || ''}
                        onChange={e => this.setState({ newCookerTypeName: e.target.value })}
                    />
                }
            </div>


            <div className="form-group">
                <label className="form-label">Floor</label>
                {/*
                <select
                    className="form-control"
                    value={cooker.floor || ''}
                    onChange={e => this.updateFields({ floor: e.target.value })}
                >
                    {floorOptions.map(f =>
                        <option key={f.value} value={f.label}>
                            {f.label}
                        </option>
                    )}
                </select>
                */}
                <input
                    type="text"
                    className="form-control"
                    value={cooker.floor || ''}
                    onChange={e => this.updateFields({ floor: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Location</label>
                {/*
                <select
                    className="form-control"
                    value={cooker.location || ''}
                    onChange={e => this.updateFields({ location: e.target.value })}
                >
                    {locationOptions.map(l =>
                        <option key={l.value} value={l.label}>
                            {l.label}
                        </option>
                    )}
                </select>*/}
                <input
                    type="text"
                    className="form-control"
                    value={cooker.location || ''}
                    onChange={e => this.updateFields({ location: e.target.value })}
                />
            </div>

            {/*
            <div className="form-group">
                <label className="form-label">Notes</label>
                <ExpandingTextArea
                    className="form-control"
                    key={cooker.notes || ''}
                    defaultValue={cooker.notes || ''}
                    onBlur={e => this.updateFields({ notes: e.target.value })}
                    rows={2}
                />
            </div>
            */}

        </>);
    }
}

export default EditCookerModal;