// Libs
import React from 'react';
import 'moment/locale/en-gb';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

// Localisation
import enGB from 'date-fns/locale/en-gb';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('en-gb', enGB);

//-------------------------------------------------------------------------------------------------------------------

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.value = this.props.value;
    }

    componentDidUpdate() {
        this.value = this.props.value;
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            value,
            onChange
        } = this.props;

        const props = {
            ...this.props
        };
        delete props.onChange;

        return (
            <ReactDatePicker
                selected={value || null}
                onSelect={this.props.onChange}
                dateFormat="dd/MM/yyyy"
                locale="en-gb"
                {...props}
            />
        );
    }
    
}

export default DatePicker;